import { useState } from 'react';
import * as api from '../../../api';
import {
  IPreHeaderRole,
  IPreHeaderRoleMessage,
  IPreHeaderRoleMessageWhiteList,
  PreHeaderRoleListKey,
  WHITE_LIST_TYPES,
} from '../models/preheader.models';
import { feedback } from '../../../utils';
import { pagesConfigurationItems } from '../constants';

const usePreHeaderWhiteList = ({
  message,
  type,
  handleChange,
  role,
}: {
  message: IPreHeaderRoleMessage;
  type: WHITE_LIST_TYPES;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
  role: IPreHeaderRole;
}) => {
  const filterWhiteListByTypeAndChecksWhiteList =
    message.preHeaderWhiteList.filter((item) => {
      return (
        item.type === type &&
        !Object.values(pagesConfigurationItems).includes(
          item.value as pagesConfigurationItems,
        )
      );
    });

  const [whiteListItems, setWhiteListItems] = useState<
    IPreHeaderRoleMessageWhiteList[]
  >(filterWhiteListByTypeAndChecksWhiteList);

  const [whiteList, setWhiteList] = useState<IPreHeaderRoleMessageWhiteList>({
    preHeaderMessageId: message.id,
    type,
    matchType: 'contains',
    value: '',
  });

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const createWhiteList = () => {
    setLoading(true);

    if (!whiteList.value) {
      setErrors({ value: 'This field is required' });
      setLoading(false);
      return;
    }

    if (!whiteList.value.startsWith('/') && type === WHITE_LIST_TYPES.URL) {
      setErrors({
        value: 'The url must start with /',
      });
      setLoading(false);
      return;
    }

    if (!whiteList.value.startsWith('utm') && type === WHITE_LIST_TYPES.UTM) {
      setErrors({
        value: 'The url must start with utm',
      });
      setLoading(false);
      return;
    }

    api
      .postDataCall({
        dataPath: '/pre-header-white-list',
        data: whiteList,
      })
      .then((response) => {
        handleChange(role.customerRole, role.active, {
          ...role,
          preHeaderMessages: (role.preHeaderMessages ?? []).map(
            (m: IPreHeaderRoleMessage) => {
              if (m.id === message.id) {
                message.preHeaderWhiteList = [...whiteListItems, response.data];
              }
              return m;
            },
          ),
        });
        setWhiteListItems([...whiteListItems, response.data]);
        setWhiteList({
          preHeaderMessageId: message.id,
          type,
          matchType: 'contains',
          value: '',
        });
      })
      .finally(() => {
        setLoading(false);
        setErrors({});
      })
      .catch((error) => {
        feedback(error.message);
      });
  };

  const deleteWhiteList = (id: string) => {
    setLoading(true);
    api
      .deleteCall({
        dataPath: `/pre-header-white-list/${id}`,
      })
      .then(() => {
        handleChange(role.customerRole, role.active, {
          ...role,
          preHeaderMessages: (role.preHeaderMessages ?? []).map(
            (m: IPreHeaderRoleMessage) => {
              if (m.id === message.id) {
                message.preHeaderWhiteList = [
                  ...message.preHeaderWhiteList.filter(
                    (item) => item.id !== id,
                  ),
                ];
              }
              return m;
            },
          ),
        });
        setWhiteListItems(whiteListItems.filter((item) => item.id !== id));
      })
      .catch((error) => {
        feedback(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    whiteListItems,
    whiteList,
    setWhiteList,
    errors,
    loading,
    createWhiteList,
    deleteWhiteList,
  };
};

export default usePreHeaderWhiteList;
