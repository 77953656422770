export enum ResourceTypeDetailEnum {
  TEXT_BODY = 1,
  INTRO = 2,
  VIDEO_BODY = 3,
  VIDEO_URL = 4,
  IMAGE_URL = 5,
  HASHTAG = 6,
  MENTION = 7,
  RETRY = 8,
  CONTINUE = 9,
  QUIZ_CONTENT = 10,
  QUESTION_VIDEO_URL = 11,
  QUESTION_IMAGE_URL = 12,
  CORRECT = 13,
  ENABLED = 14,
  CANCELED = 15,
  IN_PROGRESS = 16,
  ONLINE = 17,
  FULL = 18,
  // CODE = 19,
  CAPACITY = 20,
  CITY = 21,
  LOCATION = 22,
  URL = 23,
  EVENT_START = 24,
  EVENT_CONTENT = 25,
  // REMINDER_CONTENT = 26,
  NOTIFICATION_TIME = 27,
  SPEAKER_JOB = 28,
  FAQ_1_CONTENT = 29,
  FAQ_2_CONTENT = 30,
  FAQ_3_CONTENT = 31,
  FAQ_4_CONTENT = 32,
  FAQ_5_CONTENT = 33,
  GALLERY_IMAGE_URL = 34,
  GALLERY_VIDEO_URL = 35,
  //SPEAKER_NAME = 36,
  PRE_INSCRIPTION_TEXT = 37,
  PRE_PARTICIPATION_TEXT = 38,
  SHOW_STATISTICS = 39,
  QUESTION_JUSTIFICATION = 40,
  EVENT_END = 41,
  //42, 43
  NOTIFICATION_MAIL = 44,
  NOTIFICATION_BEFORE_BOOLEAN = 45,
  SPEAKER_IMAGE = 46,
  SPEAKER_EMAIL = 47,
  SPEAKER_PHONE = 48,
  MAPS_URL = 49,
  ABOUT_START = 50,
  MAIL = 51,
  MATERIAL_BRAND = 52,
  MATERIAL_ORDER = 53,
  MATERIAL_DESCRIPTION = 54,
  MATERIAL_CONTENT = 55,
  MATERIAL_IMAGE = 56,
  QUIZ_IMAGE = 57,
  SURVEY_ID = 58,
  SURVEY_START_DATE = 59,
  WATCH_AGAIN_YOUTUBE_ID = 60,
  WATCH_AGAIN_ONLY_ATTENDED_USERS = 61,
  SURVEY_DESCRIPTION = 62,
  SHOPPING_CART_DESCRIPTION = 63,
  SHOPPING_CART_CONDITIONS = 64,
  SHOPPING_CART_BUTTON_TEXT = 65,
  SHOPPING_CART_MAX_ITEMS = 66,
  SHOPPING_CART_MIN_ITEMS = 67,
  SHOPPING_CART_GROUP_DESCRIPTION = 68,
  SHOPPING_CART_GROUP_MAX_ITEMS = 69,
  SHOPPING_CART_GROUP_MIN_ITEMS = 70,
  SHOPPING_CART_PRODUCT_ID = 71,
  SHOPPING_CART_PRODUCT_MAX_ITEMS = 72,
  SHOPPING_CART_PRODUCT_MIN_ITEMS = 73,
  SHOPPING_CART_PRODUCT_DEFAULT_ITEMS = 74,
  RESOURCE_GUIDE_DESCRIPTION = 75,
  NOTIFICATION_STATUS = 76,
  VIDEO_DESCRIPTION = 77,
  VIDEO_OPTIONAL_IMAGE = 78,
  MATERIAL_TYPE = 79,
  CHALLENGE_MATERIALS_DESCRIPTION = 80,
  LINKED_CHALLENGE_DESCRIPTION = 81,
  LINKED_CHALLENGE_TYPE = 82,
  LINKED_CHALLENGE_ID = 83,
  SURVEY_BUTTON_TEXT = 84,
  SURVEY_END_DATE = 85,
  RESOURCE_GUIDE_SCORE = 86,
  EXPERIENCE_PROGRESS_NONE = 87,
  EXPERIENCE_PROGRESS_HALF = 88,
  EXPERIENCE_PROGRESS_FULL = 89,
  REDEEM_PRODUCT_BUTTON = 90,
  QUIZ_POINTS_LABEL = 91,
  TEXT_DESKTOP_IMAGE = 92,
  TEXT_MOBILE_IMAGE = 93,
  TEXT_IMAGE_POSITION = 94,
  SURVEY_END_TEXT = 95,
  CHECKOUT_DESCRIPTION = 96,
  CHECKOUT_QUESTION = 97,
  CHECKOUT_ADDRESS_TYPE = 98,
  CHECKOUT_BUTTON_NAME = 99,
  CHECKOUT_COMPLETED_LABEL = 100,
  CHECKOUT_PRODUCT_EAN = 101,
  CHECKOUT_PRODUCT_IMAGE = 102,
  CHECKOUT_PRODUCT_ORIGINAL_STOCK = 103,
  CHECKOUT_PRODUCT_CURRENT_STOCK = 104,
  QUIZ_TYPEFORM_BUTTON_TEXT = 105,
  QUIZ_TYPEFORM_TYPEFORM_ID = 106,
  CHECKOUT_MAIL = 107,
  CHECKOUT_SAP_MAIL = 108,
  CHALLENGE_VISIBILITY_DESCRIPTION = 109,
  CHALLENGE_VISIBILITY_BUTTON_TITLE = 110,
  CHALLENGE_VISIBILITY_ALLOWED_EXTENSIONS = 111,
  CHALLENGE_VISIBILITY_LINK_STATIC_PAGES = 112,
  EXPERIENCE_ACTIVATION_DESCRIPTION = 113,
  EXPERIENCE_ACTIVATION_BUTTON = 114,
  EXPERIENCE_ACTIVATION_LINK_TITLE = 115,
  EXPERIENCE_ACTIVATION_LINK_URL = 116,
  EXPERIENCE_ACTIVATION_MODAL_DESCRIPTION = 117,
  EXPERIENCE_ACTIVATION_MODAL_BUTTON = 118,
  SELL_OUT_DESCRIPTION = 119,
  SELL_OUT_BODY_TITLE = 120,
  SELL_OUT_BODY_DESCRIPTION = 121,
  SELL_OUT_BODY_END_DATE = 122,
  SELL_OUT_BODY_START_DATE = 123,
  SELL_OUT_BODY_MAIN_IMAGE = 124,
  SELL_OUT_DESCRIPTION_LARGE = 125,
  SELL_OUT_REPORT_FILE_TYPE = 126,
  SELL_OUT_REPORT_QUANTITY = 127,
  SELL_OUT_POINTS_RANGE_MAX = 128,
  SELL_OUT_POINTS_RANGE_MIN = 129,
  REDEEM_PRODUCT_TERMS_LINK = 130,
  STEPS_DESCRIPTION = 131,
  STEPS_CARD_SHOW_NUMBERS = 132,
  STEPS_CARD_CONTENT = 133,
  STEPS_CARD_IMAGE = 134,
  QUESTION_TYPE = 135,
  QUESTION_SHOW_STATISTICS = 136,
  TIME_TO_SHOW_LINK = 137,
  VIDEO_BUTTON = 138,
  STEPS_CARD_IMAGE_DESKTOP = 139,
  STEPS_CARD_NAME = 140,
  CHECKOUT_IMAGE_MOBILE = 141,
  CHECKOUT_IMAGE_DESKTOP = 142,
  NOTIFICATION_RECIPIENT = 143,
  SELL_IN_DESCRIPTION = 144,
  SELL_IN_CONDICTIONS = 145,
  SELL_IN_PRODUCT_ID = 146,
  SELL_IN_PRODUCT_MAX_ITEMS = 147,
  SELL_IN_PRODUCT_MIN_ITEMS = 148,
  SELL_IN_PRODUCT_DEFAULT_ITEMS = 149,
  SELL_IN_IMAGE_RANGES = 150,
  CHECKOUT_DONE_USER_SHIPPING_MAIL_SAP = 151,
  SELL_IN_IMAGE_RANGES_MIN_QUANTITY = 152,
  SELL_IN_PRODUCT_MIN_QUANTITY = 153,
  REDEEM_PRODUCT_TERMS_TEXT = 154,
  CHECKOUT_END_MESSAGE = 155,
  CHALLENGE_VISIBILITY_END_DATE = 156,
  WYSIWYG_DETAIL = 157,
  PEARL_CONDITIONS = 158,
  SHOPPING_CART_SHOW_PRICES = 159,
  STEPS_CARD_LINK = 160,
  STEPS_CARD_TEXT_LINK = 161,
  VIDEO_SECTION_ID = 162,
  VIDEO_SECTION_TYPE = 163,
  VIDEO_SECTION_DESKTOP_IMAGE = 164,
  VIDEO_SECTION_MOBILE_IMAGE = 165,
  CHECKOUT_DONE_USER_SHIPPING_MAIL = 166,
  REDEEM_PRODUCT_DESCRIPTION = 167,
  CHECKOUT_PRODUCT_ITEM_DESCRIPTION = 171,
  TEXT_LINK_LINK = 172,
  TEXT_LINK_TEXT = 173,
  VIDEO_SECTION_DESCRIPTION = 174,
  SHOPPING_CART_TYPE = 175,
  SURVEY_TYPEFORM_ID = 176,
  CHECKOUT_PRODUCT_TYPE = 177,
  QUESTION_DESCRIPTION = 178,
  IMAGE_DESCRIPTION = 179,
  EMAIL_WITH_DELAY_ID_MAIL = 180,
  EMAIL_WITH_DELAY_HOUR = 181,
  EMAIL_WITH_DELAY_DAY = 182,
  GENERIC_BUTTON_LABEL_COMPLETED = 185,
  GENERIC_BUTTON_SHOW_MODAL = 186,
  GENERIC_BUTTON_MODAL_TITLE = 187,
  GENERIC_BUTTON_MODAL_DESCRIPTION = 188,
  WYSWYG_CONTENT = 190,
  INCLUDED_ITEMS_LIST_DESCRIPTION = 194,
  INCLUDED_ITEM_ICON = 195,
  GENERIC_BUTTON_DESCRIPTION = 206,
  GENERIC_BUTTON_SHOW_SEPARATOR = 207,
  WYSWYG_DESCRIPTION = 189,
  EMAIL_WITH_DATE_ID_MAIL = 192,
  EMAIL_WITH_DATE_DATE = 193,
  TEXT_DURATION_CONTENT = 196,
  TEXT_DURATION_ICON = 197,
  QUIZ_TYPEFORM_TITLE = 198,
  QUIZ_TYPEFORM_DESCRIPTION = 199,
  QUIZ_TYPEFORM_IMAGE = 200,
  QUIZ_TYPEFORM_VIDEO = 201,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_TYPE = 202,
  ACTIVATION_BUTTON_CALLBACK_REQUEST_URL = 203,
  ACTIVATION_BUTTON_ANCHOR_IN_HEADER = 204,
  CHECKOUT_LOTTERY_BUTTON_NAME = 208,
  CHECKOUT_LOTTERY_TERMS_DOCUMENT = 209,
  CHECKOUT_LOTTERY_TERMS_LABEL = 210,
  CHECKOUT_LOTTERY_IMAGE = 211,
  CHECKOUT_LOTTERY_TEXT = 212,
  ARN_SURVEY_ID = 213,
  ARN_SURVEY_DESCRIPTION = 214,
  ARN_SURVEY_BUTTON_TEXT = 215,
  ARN_SURVEY_END_TEXT = 216,
  ARN_SURVEY_START_DATE = 217,
  ARN_SURVEY_END_DATE = 218,
  BEAUTY_GUIDE_BUTTON_LABEL = 219,
  BEAUTY_GUIDE_DESCRIPTION = 220,
  BEAUTY_GUIDE_IMAGE = 221,
  HIDDEN_TITLE = 222,
  HIDDEN_ICON = 223,
  HIDDEN_TEXT = 224,
  // 225,
  HIDDEN_CTA_TEXT = 226,
  HIDDEN_CTA_LINK = 227,
  CHECKOUT_DONATION_NAME = 228,
  CHECKOUT_DONATION_IMAGE = 229,
  CHECKOUT_DONATION_BUTTON_LEVEL = 230,
  CHECKOUT_DONATION_QUESTION_SELECT = 231,
  CHECKOUT_DONATION_CARD_DESCRIPTION = 232,
  CHECKOUT_DONATION_TERMS_LABEL = 233,
  CHECKOUT_DONATION_TERMS_DOCUMENT = 234,
  CHECKOUT_DONATION_PRODUCT = 235,
  QUIZ_DERMO_TITLE = 247,
  QUIZ_DERMO_DESCRIPTION = 243,
  QUIZ_DERMO_VIDEO = 244,
  FORMATION_CERTIFICATE_TEMPLATE = 245,
  FORMATION_CERTIFICATE_REQUIRED_POINTS = 246,
  CHECKOUT_LOTTERY_STICKY_TEXT = 251,
  CHECKOUT_LOTTERY_ORDER_DESCRIPTION = 252,
  CHECKOUT_LOTTERY_ORDER_TITLE = 253,
  CHECKOUT_LOTTERY_SHOW_ORDER = 254,
  CHECKOUT_BOOKING_TIMIFY_BUTTON_TEXT = 248,
  CHECKOUT_BOOKING_TIMIFY_TEXT = 249,
  CHECKOUT_BOOKING_TIMIFY_ID = 250,
  CHECKOUT_LOTTERY_SUCCESS_WYSWYG = 255,
  LEGAL_CHECK_URL = 257,
  CERTIFIED_COURSE_LEGAL_CHECK = 258,
  CERTIFIED_COURSE_LEGAL_CHECK_URL = 259,
  CERTIFIED_COURSE_CREDIT = 260,
  CERTIFIED_COURSE_URL = 261,
  CERTIFIED_COURSE_SPEAKER = 262,
  CERTIFIED_COURSE_EXTERNAL_PLATFORM_TYPE = 263,
  CERTIFIED_COURSE_ID_COURSE = 264,
  EXPERTS_GROUP_ICON = 265,
  EXPERTS_GROUP_DESCRIPTION = 266,
  CHECKOUT_PAYMENT_GATEWAY_DESCRIPTION = 267,
  CHECKOUT_PAYMENT_GATEWAY_QUESTION = 268,
  CHECKOUT_PAYMENT_GATEWAY_BUTTON_NAME = 269,
  CHECKOUT_PAYMENT_GATEWAY_COMPLETED_LABEL = 270,
  CHECKOUT_PAYMENT_GATEWAY_MAIL = 271,
  CHECKOUT_PAYMENT_GATEWAY_SAP_MAIL = 272,
  CHECKOUT_PAYMENT_GATEWAY_TERMS_URL = 273,
  CHECKOUT_PAYMENT_GATEWAY_TERMS_LABEL = 274,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_IMAGE = 275,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_PRICE = 276,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_POINTS = 277,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_EAN = 278,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_TYPE = 279,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_ORIGINAL_STOCK = 280,
  CHECKOUT_PAYMENT_GATEWAY_PRODUCT_CURRENT_STOCK = 281,
  WEBEAT_CHECKOUT_TC_DOCUMENT = 282,
  WEBEAT_CHECKOUT_BUTTON_LABEL = 283,
  WEBEAT_CHECKOUT_SEARCH_LABEL = 284,
  WEBEAT_CHECKOUT_PRODUCT_ID = 285,
  WEBEAT_CHECKOUT_PRODUCT_DISABLED = 286,
  WEBEAT_CHECKOUT_PRODUCT_STOCK_LIMIT = 287,
  WEBEAT_CHECKOUT_PRODUCT_STOCK_CONSUMED = 288,
  EVENT_QUIZ_TYPEFORM_TYPEFORM_ID = 289,
  EVENT_QUIZ_TYPEFORM_EVENT_URL = 290,
  EVENT_QUIZ_TYPEFORM_BUTTON_TEXT = 291,
  EVENT_QUIZ_TYPEFORM_EVENT_START_DATE = 292,
  EVENT_QUIZ_TYPEFORM_EVENT_END_DATE = 293,
  UPLOAD_MEDIA_NEW = 300,
  CLAUSE_ID = 308,
  CLAUSE_COMPLETED_MESSAGE = 309,
  CLAUSE_BUTTON_TEXT = 310,
  EXTERNAL_REDEEM_POINTS_DESCRIPTION = 311,
  EXTERNAL_REDEEM_POINTS_TYPE = 312,
  EXTERNAL_REDEEM_POINTS_BUTTON_LABEL = 313,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_DESCRIPTION = 314,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_FILE = 315,
  EXTERNAL_REDEEM_POINTS_TC_MODAL_BUTTON_LABEL = 316,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_TITLE = 317,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_DESCRIPTION = 318,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_WARNING = 319,
  EXTERNAL_REDEEM_POINTS_COMPLETE_MODAL_BUTTON_LABEL = 320,
  EXTERNAL_REDEEM_POINTS_POINTS_ACCEPTANCE_MESSAGE = 321,
  EXTERNAL_REDEEM_POINTS_POINTS_VALUE = 322,
  EXTERNAL_REDEEM_POINTS_POINTS_CARD_LABEL = 323,
  EXTERNAL_REDEEM_POINTS_POINTS_COUPON_CODE = 324,
  LEARNING_QUIZ_DESCRIPTION = 325,
  LEARNING_QUIZ_MEDIA_URL = 326,
  LEARNING_QUIZ_REPEATABLE = 327,
  LEARNING_QUIZ_MAX_REPETITIONS = 328,
  LEARNING_QUIZ_QUESTION_TYPE = 329,
  LEARNING_QUIZ_ANSWER_CHECK = 330,
  LEARNING_QUIZ_TITLE = 331,
  QUIZ_TYPEFORM_POINTS_BEHAVIOUR = 332,
  QUIZ_TYPEFORM_STICKY = 333,
  TEXT_DESCRIPTION_LINK = 334,
  TEXT_URL_LINK = 335,
  QUESTION_VIDEO_IS_VERTICAL = 336,
  VIDEO_IS_VERTICAL = 337,
  WEBEAT_CHECKOUT_PRODUCT_COPAY = 338,
  WEBEAT_CHECKOUT_PRODUCT_PRICE = 339,
  QUIZ_TYPEFORM_TYPE = 340,
  SURVEY_ICON = 341,
  VIDEO_ICON = 342,
  WEBEAT_ISDIN_HOUSE_BOOKING_TERMS_DOCUMENT = 343,
  WEBEAT_ISDIN_HOUSE_BOOKING_BUTTON_LABEL = 344,
}

export enum DetailsTypesEnum {
  ARN_SURVEY = 'arnSurvey',
  BOOLEAN = 'boolean',
  BRAND = 'brand',
  CHALLENGE = 'challenge',
  CHALLENGE_TYPE = 'challengeType',
  CHECKOUT_ADDRESS_TYPE = 'addressType',
  CHECKOUT_PRODUCT_TYPE = 'productType',
  CLAUSE = 'salesforceClauses',
  DATE = 'timestamp',
  EXTERNAL_CERTIFIED_COURSES = 'externalCertifiedCourse',
  EXTERNAL_PLATFORM = 'externalPlatformType',
  ICON_TYPE = 'iconType',
  IMAGE = 'image',
  IMAGE_POSITION = 'textImagePosition',
  LINK_STATIC_PAGE = 'linkStaticPage',
  MAIL = 'mail',
  MATERIAL_TYPE = 'materialType',
  NOTIFICATION_RECIPIENT = 'notificationRecipient',
  NUMBER = 'number',
  PRODUCT = 'product',
  QUESTION_TYPE = 'questionType',
  REQUEST_TYPES_TYPE = 'requestTypesType',
  SHOPPING_CART_TYPE = 'shoppingCartType',
  SURVEY = 'survey',
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  TIME = 'time',
  UPLOAD = 'upload',
  UPLOAD_MEDIA = 'media-upload',
  UPLOAD_MEDIA_TYPE = 'uploadMediaType',
  URL = 'url',
  VIDEO_SECTION_TYPE = 'videoSectionType',
  WYSWYG = 'wyswyg',
  EXTERNAL_REDEEM_POINTS_TYPE = 'externalRedeemPointsType',
  WEBEAT_CHECKOUT_PRODUCT_TYPE = 'webeatProductType',
  WEBEAT_CHECKOUT_PRODUCT_PRICE = 'webeatProductPrice',
  LEARNING_QUIZ_QUESTION_TYPE = 'learningQuizQuestionType',
}

export const isTextDetail = (type: string | number) => {
  if (type === DetailsTypesEnum.TEXT || type === DetailsTypesEnum.TEXT_AREA)
    return true;
  else return false;
};

export const isBooleanDetail = (type: string | number) =>
  type === DetailsTypesEnum.BOOLEAN;

export const isIconTypeDetail = (type: string | number) =>
  type === DetailsTypesEnum.ICON_TYPE;

export const isUrlDetail = (type: string | number) =>
  type === DetailsTypesEnum.URL;

export enum QuizQuestionTypeEnum {
  UNIQUE = '0',
  MULTIRESPONSE = '1',
  ALL_VALID = '2',
}

export enum ExternalRedeemPointsTypeEnum {
  EXTERNAL_REDEEM_DONATION = '1',
  EXTERNAL_REDEEM_ISD = '2',
}
