export enum WebeatPreHeaderRoleType {
  All = 'All',
  Guest = 'Guest',
  Registered = 'Registered',
  RegisteredLover = 'RegisteredLover',
  RegisteredFriend = 'RegisteredFriend',
}

export const AllRoleKey = 'All';
export const GuestRoleKey = 'Guest';
export const RegisteredRoleKey = 'Registered';
export const RegisteredLoverRoleKey = 'RegisteredLover';
export const RegisteredFriendRoleKey = 'RegisteredFriend';

export type PreHeaderRoleListKey =
  | typeof AllRoleKey
  | typeof GuestRoleKey
  | typeof RegisteredRoleKey
  | typeof RegisteredLoverRoleKey
  | typeof RegisteredFriendRoleKey;

export interface IPreHeaderRoles {
  id: string;
  preHeaderRoles?: IPreHeaderRole[];
  active: boolean;
  backgroundColor: string;
  initialDate: string;
  finalDate: string;
  textColor: string;
}
export interface IPreHeaderRoleMessage {
  id: string;
  preHeaderRolesId: string;
  message: string;
  icon: string | null;
  order: number;
  active: boolean;
  preHeaderWhiteList: IPreHeaderRoleMessageWhiteList[];
}

export type MessageWhiteListMatchType = 'contains' | 'exact';
export interface IPreHeaderRoleMessageWhiteList {
  id?: string;
  preHeaderMessageId: string;
  type: WHITE_LIST_TYPES;
  matchType: MessageWhiteListMatchType;
  value: string;
}

export interface IPreHeaderRole {
  id: string;
  preHeaderId: string;
  customerRole: keyof typeof WebeatPreHeaderRoleType;
  active: boolean;
  preHeaderMessages?: IPreHeaderRoleMessage[];
  initialDate: Date;
  finalDate: Date;
}

export interface Icon {
  description: string;
  value: string;
  svg: string;
}

export enum WHITE_LIST_TYPES {
  URL = 'url',
  UTM = 'utm',
}

export const roleDependencies = {
  [WebeatPreHeaderRoleType.All]: {
    message: 'Only this role will be active!',
    deactivate: (r: IPreHeaderRole) =>
      r.customerRole !== WebeatPreHeaderRoleType.All,
  },
  [WebeatPreHeaderRoleType.Guest]: {
    message: 'All users role will be deactivated!',
    deactivate: (r: IPreHeaderRole) =>
      r.customerRole === WebeatPreHeaderRoleType.All,
  },
  [WebeatPreHeaderRoleType.Registered]: {
    message: 'All users and/or Lover/Friend will be deactivated!',
    deactivate: (r: IPreHeaderRole) =>
      r.customerRole === WebeatPreHeaderRoleType.All ||
      r.customerRole === WebeatPreHeaderRoleType.RegisteredFriend ||
      r.customerRole === WebeatPreHeaderRoleType.RegisteredLover,
  },
  [WebeatPreHeaderRoleType.RegisteredLover]: {
    message: 'All users and/or Registered roles will be deactivated!',
    deactivate: (r: IPreHeaderRole) =>
      r.customerRole === WebeatPreHeaderRoleType.All ||
      r.customerRole === WebeatPreHeaderRoleType.Registered,
  },
  [WebeatPreHeaderRoleType.RegisteredFriend]: {
    message: 'All users and/or Registered roles will be deactivated!',
    deactivate: (r: IPreHeaderRole) =>
      r.customerRole === WebeatPreHeaderRoleType.All ||
      r.customerRole === WebeatPreHeaderRoleType.Registered,
  },
};
