import React, { FC, useEffect, useState } from 'react';
import {
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from './models/preheader.models';
import Text from 'antd/lib/typography/Text';
import { Icon, IPreHeaderRole } from './models/preheader.models';
import { PreHeaderMessageFields } from './components/preHeaderMessageFields/PreHeaderMessageFields';
import { Switch } from 'antd';

export const WebeatPreHeaderRolesFields: FC<{
  active: boolean;
  preHeaderId?: string;
  parentProps: any;
  role: IPreHeaderRole;
  icons: Icon[];
  updateRoles: any;
  roles: IPreHeaderRole[];
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
}> = ({
  role,
  active,
  icons,
  preHeaderId,
  handleChange,
  updateRoles,
  roles,
}) => {
  const [activeSwitch, setActiveSwitch] = useState(active);

  const handleChangeActive = (value: boolean) => {
    role.active = activeSwitch;
    setActiveSwitch(value);

    if (handleChange) {
      updateRoles(role, roles, value);
      handleChange(role.customerRole, value, {
        ...role,
        active: activeSwitch,
        preHeaderMessages: role.preHeaderMessages?.map(
          (m: IPreHeaderRoleMessage) => {
            if (!value) {
              m.active = false;
            }
            return m;
          },
        ),
      });
    }
  };

  useEffect(() => {
    setActiveSwitch(active);
  }, [active]);

  return (
    <div className="webeat-preheader-role__panel--content">
      <div
        style={{
          display: 'flex',
          gap: 10,
          alignItems: 'center',
        }}>
        <Switch
          checked={role.active}
          onChange={(e) => handleChangeActive(e)}
          disabled={false}
        />
        <Text>Active</Text>
      </div>
      <br />
      <div>
        <PreHeaderMessageFields
          preHeaderId={preHeaderId}
          handleChange={handleChange}
          role={role}
          icons={icons}
          messages={role.preHeaderMessages || []}
        />
        <br />
        <br />
      </div>
    </div>
  );
};
