import React, { FC, useEffect, useState } from 'react';
import { Collapse, Modal, Row } from 'antd';
import {
  AllRoleKey,
  GuestRoleKey,
  IPreHeaderRoles,
  PreHeaderRoleListKey,
  RegisteredRoleKey,
  roleDependencies,
} from './models/preheader.models';
import { WebeatPreHeaderRolesFields } from './WebeatPreHeaderRolesFields';
import { Icon, IPreHeaderRole } from './models/preheader.models';
import './WebeatPreHeaderRoles.css';
import * as api from '../../api';
import { sortRoles } from './utils/sorted-roles';
import { Result } from 'antd';

const { Panel } = Collapse;

export const WebeatPreHeader: FC<{
  values: IPreHeaderRoles;
  parentProps: any;
  field: { key: string; type: string };
  handleChangeField: (params: {
    type: string;
    id: string;
    value: any;
    format?: string;
  }) => Promise<void> | void;
}> = ({ values, parentProps, handleChangeField, field }) => {
  const sortedRoles = sortRoles(values);
  const [roles, setRoles] = useState<IPreHeaderRole[]>(sortedRoles || []);
  const [
    warningMessageAfterRoleActivation,
    setWarningMessageAfterRoleActivation,
  ] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [icons, setIcons] = useState<Icon[]>([]);

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const updateRoles = (
    role: IPreHeaderRole,
    roles: IPreHeaderRole[],
    active: boolean,
  ): void => {
    const dependency = roleDependencies[role.customerRole];
    let areDependenciesActive = false;
    if (!active && !dependency) return;

    const anyMessageActive =
      role.preHeaderMessages?.some((m) => m.active) ?? false;

    roles.forEach((r) => {
      if (active && dependency.deactivate(r)) {
        r.active = false;
        r.preHeaderMessages?.forEach((m) => (m.active = false));
        areDependenciesActive = true;
      }
    });

    if (active && !anyMessageActive && areDependenciesActive) {
      setIsModalVisible(true);
      setWarningMessageAfterRoleActivation(dependency.message);
    }

    setRoles(roles);
    handleChangeField({
      type: field.type,
      id: field.key,
      value: roles,
    });
  };

  const handleChangeRole = (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => {
    const updatedRoles = roles.map((item: IPreHeaderRole) => {
      if (item.customerRole === roleKey) {
        return {
          ...role,
          ...item,
          active,
        };
      }
      return item;
    });

    setRoles(updatedRoles);
    handleChangeField({
      type: field.type,
      id: field.key,
      value: updatedRoles,
    });
  };

  useEffect(() => {
    api
      .getDataCall({
        dataPath: '/design-resources/icon',
        callConfig: {},
      })
      .then((response) => {
        setIcons(response.data);
      });
  }, []);

  useEffect(() => {
    setRoles(sortedRoles || []);
  }, [sortedRoles]);

  return (
    <div>
      <Collapse accordion>
        {roles.map((role: IPreHeaderRole) => {
          if (role.active === null) {
            role.active = false;
          }
          return (
            <Panel
              header={role.customerRole}
              key={role.id}
              className={`${
                role?.active
                  ? 'webeat-preheader-role__panel--active'
                  : 'webeat-preheader-role__panel--inactive'
              } ${
                role.customerRole === RegisteredRoleKey ||
                role.customerRole === GuestRoleKey
                  ? 'webeat-preheader-role__panel--registered'
                  : role.customerRole === AllRoleKey
                  ? ''
                  : 'webeat-preheader-role__panel--registered-custom'
              }
              `}>
              <WebeatPreHeaderRolesFields
                active={role?.active}
                preHeaderId={values.id}
                parentProps={parentProps}
                handleChange={handleChangeRole}
                role={role}
                icons={icons}
                updateRoles={updateRoles}
                roles={roles}
              />
            </Panel>
          );
        })}
      </Collapse>
      <Modal
        visible={isModalVisible}
        footer={false}
        onCancel={handleModalCancel}>
        <Row>
          <Result status="info" title={warningMessageAfterRoleActivation} />
        </Row>
      </Modal>
    </div>
  );
};
