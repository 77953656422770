import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  IPreHeaderRoleMessage,
  PreHeaderRoleListKey,
} from '../../models/preheader.models';

import { Icon, IPreHeaderRole } from '../../models/preheader.models';
import Title from 'antd/lib/typography/Title';

import { PreHeaderSVGSelector } from '../preHeaderSVGSelector/PreHeaderSVGSelector';
import PreHeaderPagesConfiguration from '../preHeaderPagesConfiguration/PreHeaderPagesConfiguration';

import { Col, Collapse, Modal, Row, Switch } from 'antd';

import { transformHtmlToString } from '../../utils/cleanHtmlTags';
import PreHeaderMessage from '../preHeaderMessages/PreHeaderMessage';

export const PreHeaderMessageFields: FC<{
  messages: IPreHeaderRoleMessage[];
  preHeaderId?: string;
  handleChange: (
    roleKey: PreHeaderRoleListKey,
    active: boolean,
    role: IPreHeaderRole,
  ) => void;
  role: IPreHeaderRole;
  icons: Icon[];
  active?: boolean;
}> = ({ handleChange, role, icons, messages }) => {
  const sortedMessages = useCallback(() => {
    if (messages[0].order === undefined) {
      messages.forEach((message, index) => {
        message.order = index + 1;
      });
    }
    messages.sort((a, b) => a.order - b.order);
    return messages;
  }, [messages]);

  const { Panel } = Collapse;
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const [orderedMessages, setOrderedMessages] = useState<
    IPreHeaderRoleMessage[]
  >(sortedMessages());

  const onDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (dropIndex: number) => {
    if (draggedItemIndex === null) return;

    const updatedItems = orderedMessages;

    const [draggedItem] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(dropIndex, 0, draggedItem);
    updatedItems.forEach((item, index) => {
      if (item) {
        item.order = index + 1;
      }
    });

    setOrderedMessages(updatedItems);
    handleChange(role.customerRole, !!role.active, {
      ...role,
      preHeaderMessages: updatedItems,
    });
    setDraggedItemIndex(null);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(true);
  const handleChangeActiveMessage = (
    message: IPreHeaderRoleMessage,
    checked: boolean,
    e: any,
  ) => {
    e.stopPropagation();
    const activeMessagesCount = orderedMessages!.filter(
      (m: IPreHeaderRoleMessage) => m.active,
    ).length;

    if (!!role.active && !checked && activeMessagesCount === 1) {
      setIsModalVisible(true);
      return;
    }

    setOrderedMessages(
      orderedMessages!.map((m: IPreHeaderRoleMessage) => {
        if (m.id === message.id) {
          m.active = checked;
        }
        return m;
      }),
    );

    handleChange(role.customerRole, !!role.active, {
      ...role,
      preHeaderMessages: orderedMessages,
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsSwitchDisabled(!role.active);
  }, [role.active]);

  useEffect(() => {
    setOrderedMessages(sortedMessages);
  }, [orderedMessages, sortedMessages]);

  return (
    <div>
      <Title level={2}>Message List</Title>
      <br />
      {orderedMessages.map((message: IPreHeaderRoleMessage, i: number) => (
        <div
          key={i}
          draggable
          onDragStart={() => onDragStart(i)}
          onDragOver={(e) => onDragOver(e)}
          onDrop={(e) => onDrop(i)}>
          <Collapse accordion>
            <Panel
              header={
                <Row>
                  <Col span={23}>
                    {transformHtmlToString(message.message) ||
                      'Configure message'}
                  </Col>
                  <Col span={1}>
                    <Switch
                      size="small"
                      disabled={isSwitchDisabled}
                      checked={role.preHeaderMessages![i].active}
                      onChange={(checked, event) => {
                        handleChangeActiveMessage(
                          role.preHeaderMessages![i],
                          checked,
                          event,
                        );
                      }}
                    />
                  </Col>
                </Row>
              }
              key={message.id}
              className={
                role.preHeaderMessages![i].active
                  ? 'webeat-preheader-role__message--active'
                  : 'webeat-preheader-role__message--inactive'
              }>
              <PreHeaderMessage
                handleChange={handleChange}
                role={role}
                message={message}
              />
              <br />
              <Title level={3}>Select Icon </Title>
              <div
                style={{
                  width: '50%',
                }}>
                <PreHeaderSVGSelector
                  imagesData={icons}
                  value={message.icon || ''}
                  handleChange={handleChange}
                  role={role}
                  message={message}
                />
              </div>
              <br />
              <br />
              <PreHeaderPagesConfiguration
                role={role}
                message={message}
                handleChange={handleChange}
              />
              <br />
              <br />
              <br />
            </Panel>
          </Collapse>
        </div>
      ))}
      <Modal
        visible={isModalVisible}
        footer={false}
        onCancel={handleModalCancel}
        title={'Last active message'}>
        <p>
          {
            'The last active message cannot be deactivated when the role is active'
          }
        </p>
      </Modal>
    </div>
  );
};
